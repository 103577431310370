.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  min-width: 250px; /* Minimum width of the tooltip */
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the text */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  opacity: 0;
  transition: opacity 0.3s;
  /* Ensure tooltip is not read twice by screen readers */
  pointer-events: none;
}

.tooltip:hover .tooltip-text,
.tooltip:focus-within .tooltip-text {
  visibility: visible;
  opacity: 1;
}
