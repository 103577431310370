.smartButton {
  background-color: #e0f5ff;
  border-radius: 6px;
  border-width: 0;
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  height: 35px;
  margin: 12px 0 0 auto;
  padding: 0 25px;
  min-width: 200px;
}
