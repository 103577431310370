.App-link {
  color: #61dafb;
}

.menu a {
  margin-right: 10px;
}

.menu {
  display: flex;
  line-height: 1em;
}

.user {
  margin-left: auto;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #f2f2f2;
  color: #333;
  transition: border-color 0.3s ease;
}

.icons {
  margin-left: 0px;
  margin-right: 1px;
}
