.project {
  margin-bottom: 30px; /* Adds px of space below the element */
  font-size: 17px; /* % of the parent element's font size */
}

.task {
  font-size: 16px;
}

ul ul {
  margin-left: 10px; /* Indents the inner ul px to the right */
}
