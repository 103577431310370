.command {
  margin-bottom: 20px;
}

.command label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  margin-bottom: 10px;
  row-gap: 10px;
}

.helpIcon {
  color: white;
  margin: 10px;
  border-radius: 60px;
  width: 25px;
  background-color: rgb(130, 151, 219);
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.commandRow {
  display: flex;
  flex-direction: row;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.commandBottom {
  display: grid;
  grid-template-columns: auto auto; /* Two columns */
  align-items: center; /* Align vertically */
  width: 100%;
}

.StatusBar {
  margin-right: 10px; /* Add space between the span and the button */
}
