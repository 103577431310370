.error {
  color: red;
  background: #ff000024;
  border-radius: 10px;
  margin-top: 10px;
}

.error .close {
  color: black;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 0;
  cursor: pointer;
  float: right;
}

.error .close::after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  font-size: 30px;
  padding-left: 1px;
}

.errorMessage {
  padding: 20px;
  margin: 10px;
}
