.explanation {
  margin-bottom: 30px;
  background: #00ddff24;
  border-radius: 10px;
  margin-top: 10px;
  padding: 30px;
}

:where(.newEncryptionPasswordInput div) {
  margin-bottom: 10px;
}

.formfield {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.formfield input[type="password"] {
  width: 300px;
}

.formfield label {
  display: flex;
  align-items: center;
}

input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin: auto;
}
