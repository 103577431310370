footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

footer ul {
  display: flex;
  flex-direction: row;
}

footer li {
  margin-left: 5px;
  margin-right: 5px;
}

footer hr {
  margin-left: 0;
  margin-right: 0;
}

footer .version {
  margin-right: auto;
}

.link-icon img {
  min-width: 24px;
  width: 24px;
  height: 24px;
}

@media (max-width: 400px) {
  .copyright {
    display: none; /* Hide the copyright text on small devices */
  }
}
