/* react template */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* html reset and basic styling */
html {
  box-sizing: border-box;
  font-size: 16px;
  margin: 0 auto;
  background-color: lightgray;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  background: white;
  padding: 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
}

li {
  margin-bottom: 5px;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin-bottom: 5px;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

/* Media queries */
/* for-phone-only */
@media (max-width: 599px) {
}

/* for-tablet-portrait-up */
@media (min-width: 600px) {
  html {
    width: 600px;
  }
}

/* for-tablet-landscape-up */
@media (min-width: 900px) {
  html {
    width: 800px;
  }
}

/* for-desktop-up */
@media (min-width: 1200px) {
}

/* for-medium-desktop-up */
@media (min-width: 1400px) {
}

/* for-big-desktop-up */
@media (min-width: 1800px) {
}
